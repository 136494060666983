body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main-content {
  /* to not break create/edit forms by adding footer */
  padding: 24px 24px 100px 24px !important;
  color: rgba(37,43,50,.74);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6 {
  color: #252b32;
  font-weight: 400;
}

.custom-table {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  border: none;
}

.custom-table .MuiDataGrid-columnSeparator {
  display: none !important;
}

.custom-table .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

/* width */
.images-holder::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}

/* Track */
.images-holder::-webkit-scrollbar-track {
  background: rgba(36,31,56,0.05);
}

/* Handle */
.images-holder::-webkit-scrollbar-thumb {
  background: #255e96;
}

/* Handle on hover */
.images-holder::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.images-holder {
  flex-wrap: wrap;
  margin-top: 20px;
}

.RaCreateButton-floating {
  left: calc(50% - 24px) !important;
  bottom: 20px !important;
}

.MuiFormHelperText-root {
  margin: 0 !important;
}

.RaFilter-form {
  gap: 15px !important;
}

.MuiFormControl-fullWidth {
  padding-top: 5px !important;
}

.RaSimpleFormIterator-line {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
  padding: 15px 0 !important;
}

.RaToolbar-defaultToolbar {
  justify-content: flex-end !important;
}

.MuiToolbar-root {
  justify-content: flex-end !important;
}

.is-editor-empty {
  color: #a9a9a9;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}